import React from "react";
import { Grid, Button, Divider } from '@mui/material'
import { useNavigate } from "react-router-dom";
import './main-layout.scss'

function MainLayout({ children }) {
    const navigate = useNavigate();

    const handleClick = (ref) => {
        navigate(ref)
    }

    return (
        <Grid container className="main-container" justifyContent="center">
            <Grid container item xs={12} className="main-container__header" justifyContent="center">
                <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/')}>Processar Arquivo</Button>
                <Divider orientation="vertical" style={{ height: "30px", marginTop: "15px" }} />
                <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/vehicles')}>Veículos</Button>
            </Grid>
            <Grid container alignItems="center" item xs={10} className="main-container__content">
                {children}
            </Grid>
        </Grid >
    )
}

export default MainLayout