import { useState } from "react"
import { Box, Button, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material"
import Divider from "../../components/Divider"
import Select from "../../components/Select"
import { parseFilename } from "../../utils/parseFilename"
import DateRangePicker from "../../components/DateRangePicker"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getReport } from "../../utils/api"
import { format } from 'date-fns';
import MainLayout from "../../layouts"
import './results.scss'

function ResultsPage() {
    const [client, setClient] = useState("")
    const [expressions, setExpressions] = useState([]);
    const [locations, setLocations] = useState([])
    const [languages, setLanguages] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [processedFileName, setProcessedFileName] = useState("")
    const [responseFileURL, setResponseFileURL] = useState()
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleSimpleChange = (event) => {
        const value = event.target.value
        setClient(value)
    }

    // const handleMultipleChange = (event) => {
    //     const {
    //         target: { value, name },
    //     } = event;

    //     eval(`set${capitalizeFirstLetter(name)}`)(typeof value === 'string' ? value.split(',') : value)

    // };

    const handleSubmit = () => {
        setIsLoading(true)
        const formattedStartDate = `${format(startDate, 'yyyy-MM-dd')}T00:00:00`
        const formattedEndDate = `${format(endDate, 'yyyy-MM-dd')}T23:59:59`

        getReport(formattedStartDate, formattedEndDate).then(response => {
            const file = new Blob([response.data], { type: response.data.type });
            const fileURL = URL.createObjectURL(file);
            setProcessedFileName(parseFilename(response.headers['content-disposition']))
            setResponseFileURL(fileURL)
            setIsLoading(false)
        }
        ).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }

    const handlePrev = () => {
        setProcessedFileName("")
        setResponseFileURL("")
    }

    return (
        <MainLayout>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid container style={{ width: "348px", marginBottom: "10px" }} justifyContent="space-evenly">
                    <img src="/cisp1.svg" style={{ width: "100px" }} />
                    <img src="/sing.png" style={{ width: "100px" }} />
                </Grid>
                <Paper elevation={0} square={false} style={{ width: '348px' }}>
                    {processedFileName ?
                        < Box sx={{ padding: '16px' }}>
                            <Typography variant="h6" style={{ textAlign: "center" }}>Arquivo Processado</Typography>
                            <Box py={1} />
                            <Divider />
                            <Box py={1} />
                            <Grid container justifyContent="center" direction="column">
                                <IconButton
                                    component="label"
                                    className="file-button"
                                >
                                    <a href={responseFileURL} download={'results.xlsx'}>
                                        <FileDownloadIcon style={{ fontSize: "100px", color: '#1976d2' }} />
                                    </a>
                                </IconButton>
                                <Typography variant="body1" style={{ textAlign: "center" }}>{processedFileName}</Typography>
                                <Button variant="contained" onClick={handlePrev} style={{
                                    width: "300px",
                                    marginLeft: "8px",
                                    marginTop: "16px",
                                    height: "56px"
                                }}>Voltar</Button>
                            </Grid>
                        </Box>
                        :

                        <Box sx={{ padding: '16px' }}>
                            <Typography variant="h6" style={{ textAlign: "center" }}>Gerar Relatório</Typography>
                            <Box py={1} />
                            <Select label={"Cliente"} selectedValue={client} onChange={handleSimpleChange} options={[{ label: "NVIDIA", value: 1 }]} />
                            {/* <MultipleSelect name="expressions" label="Expressões" selectedValue={expressions} onChange={handleMultipleChange} options={[{ label: 'expressão 1', value: 'expressão 1' }, { label: 'expressão 2', value: 'expressão 2' }]} />
                        <MultipleSelect name="locations" label="Localidade" selectedValue={locations} onChange={handleMultipleChange} options={[{ label: 'Brasil', value: 'Brasil' }, { label: 'São Paulo, Brasil', value: 'São Paulo, Brasil' }]} />
                        <MultipleSelect name="languages" label="Idiomas" selectedValue={languages} onChange={handleMultipleChange} options={[{ label: 'Português(Br)', value: 'Português(Br)' }, { label: 'Inglês', value: 'Inglês' }]} /> */}
                            <DateRangePicker startDate={startDate} endDate={endDate} onChange={onDateChange} />
                            <Button variant="contained" onClick={handleSubmit} disabled={!client || isLoading} style={{
                                width: "300px",
                                marginLeft: "8px",
                                marginTop: "16px",
                                height: "56px"
                            }}>{isLoading ? <CircularProgress style={{ color: "white" }} /> : "Gerar"}</Button>
                        </Box>
                    }
                </Paper>
            </Grid>
        </MainLayout>
    )
}

export default ResultsPage