import { FormControl, InputLabel, MenuItem, Select as MUISelect } from "@mui/material"

export default function Select({ label, selectedValue, onChange, options }) {
    return (<FormControl variant="outlined" sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id={`{${label}-label`}>{label}</InputLabel>
        <MUISelect
            labelId={`{${label}-label`}
            id={label}
            value={selectedValue}
            onChange={onChange}
            label={label}
        >
            {options.map(({ label, value }) => (
                <MenuItem value={value} key={`${label}-${value}`}>{label}</MenuItem>
            ))}
        </MUISelect>
    </FormControl>)
}