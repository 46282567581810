import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getReport = async (start_date, end_date) => {
    return await axiosInstance.get(`/report?start_date=${start_date}&end_date=${end_date}`, {
        responseType: 'blob'
    })
}

export const getVehicles = async () => {
    return await axiosInstance.get(`/vehicles`)
}

export const updateVehicle = async (id, updatedData) => {
    return await axiosInstance.put(`/vehicles/${id}`, { ...updatedData })
}


